import { Route, Redirect } from 'react-router-dom';
import React, { Suspense, lazy, Fragment } from 'react';
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';

const CartaServicos = lazy(() => import('../../Pages/CartaServicos/Index'));
const Servico       = lazy(() => import('../../Pages/CartaServicos/Servico'));
const FiltraServicos = lazy(() => import('../../Pages/CartaServicos/FiltraServicos'));
const BuscaServicos = lazy(() => import('../../Pages/CartaServicos/BuscaServicos'));

const AppMain = () => {

    return (
        <Fragment>
            {/* Login */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/carta-servicos" component={CartaServicos} />
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/filtra-servicos" component={FiltraServicos} />
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/busca-servicos" component={BuscaServicos} />
            </Suspense>

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat" />
                        </div>
                    </div>
                </div>
            }>
                <Route path="/servico" component={Servico} />
            </Suspense>


            <Route exact path="/" render={() => (
                <Redirect to="/carta-servicos" />
            )} />
            <ToastContainer />
        </Fragment>
    )
};

export default AppMain;